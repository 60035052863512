/** @jsx jsx */
import {jsx} from 'theme-ui'
import {Layout} from '../components'
import {Container} from '../elements'
import Navigation from '../components/navigation'

const Error404 = () => (
  <Layout>
    <Navigation lang="de-ch" customNav />
    <Container sx={{}}>
      <div
        sx={{
          mt: '8rem',
          textAlign: 'center',
        }}
      >
        <h1>Dalia Pugatsch</h1>
        <p>
          Die angeforderte Seite wurde nicht gefunden.
          <br />
          La page demandée n’a pas pu être trouvée.
          <br />
          Page not found.
          <br />
          Esta pagina no existe.
        </p>
      </div>
    </Container>
  </Layout>
)

export default Error404
